<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox" style="border-bottom:0px solid #001528">
				<div class="headerinfos">
					<p @click="$router.go(-2)" style="color: #cbcb42;cursor: pointer;">学生列表</p>
					<p>/</p>
					<p @click="$router.go(-1)" style="color: #cbcb42;cursor: pointer;">{{stuname}}</p>
					<p>/</p>
					<p>历史数据</p>
				</div>
			</div>
			<el-table :data="tableData" border :default-sort="{prop: 'date', order: 'descending'}"
				:header-cell-style="{textAlign: 'center'}" style="margin: 0 auto; margin-top: 10px;">
				<el-table-column prop="index" label="序号" width="200" align="center">
					<template slot-scope="scope">
						{{ (currpage - 1) * pagesize + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column prop="timexq" label="日期" align="center"></el-table-column>
				<el-table-column prop="Leyexq" label="裸眼视力（左）" align="center" v-if="type_num == 1">
				</el-table-column>
				<el-table-column prop="zqjxq" label="球镜（左）" align="center" v-else-if="type_num == 2">
				</el-table-column>
				<el-table-column prop="zzjxq" label="柱镜（左）" align="center" v-else-if="type_num == 3">
				</el-table-column>
				<el-table-column prop="zzwxq" label="散光轴位（左）" align="center" v-if="type_num == 4">
				</el-table-column>
				<el-table-column prop="zmm1xq" label="MM1（左）" align="center" v-if="type_num == 5">
				</el-table-column>
				<el-table-column prop="zmm2xq" label="MM2（左）" align="center" v-if="type_num == 6">
				</el-table-column>
				<el-table-column prop="za1xq" label="A1（左）" align="center" v-if="type_num == 7">
				</el-table-column>
				<el-table-column prop="tongjxq" label="瞳距" align="center" v-if="type_num == 8">
				</el-table-column>
				<el-table-column prop="jyjxq" label="镜眼距" align="center" v-if="type_num == 9">
				</el-table-column>
				<el-table-column prop="zxdtjxq" label="正相对调节" align="center" v-if="type_num == 10">
				</el-table-column>
				<el-table-column prop="fxdtjxq" label="负相对调节" align="center" v-if="type_num == 11">
				</el-table-column>
				<el-table-column prop="z_lmdxq" label="单眼" align="center" v-if="type_num == 12">
				</el-table-column>
				<el-table-column prop="z_yanyxq" label="左眼" align="center" v-if="type_num == 13">
				</el-table-column>
				<el-table-column prop="z_wsygxq" label="雾视" align="center" v-if="type_num == 14">
				</el-table-column>
				<el-table-column prop="bccxq" label="BCC" align="center" v-if="type_num == 16">
				</el-table-column>
				<el-table-column prop="apmtjxq" label="AMP调节幅度" align="center" v-if="type_num == 15">
				</el-table-column>

				<el-table-column prop="Reyexq" label="裸眼视力（右）" align="center" v-if="type_num == 1">
				</el-table-column>
				<el-table-column prop="yqjxq" label="球镜（右）" align="center" v-if="type_num == 2">
				</el-table-column>
				<el-table-column prop="yzjxq" label="柱镜（右）" align="center" v-if="type_num == 3">
				</el-table-column>
				<el-table-column prop="yzwxq" label="散光轴位（右）" align="center" v-if="type_num == 4">
				</el-table-column>
				<el-table-column prop="ymm1xq" label="MM1（右）" align="center" v-if="type_num == 5">
				</el-table-column>
				<el-table-column prop="ymm2xq" label="MM2（右）" align="center" v-if="type_num == 6">
				</el-table-column>
				<el-table-column prop="ya1xq" label="A1（右）" align="center" v-if="type_num == 7">
				</el-table-column>
				<el-table-column prop="y_lmdxq" label="双眼" align="center" v-if="type_num == 12">
				</el-table-column>
				<el-table-column prop="y_yanyxq" label="右眼" align="center" v-if="type_num == 13">
				</el-table-column>
				<el-table-column prop="y_wsygxq" label="不雾视" align="center" v-if="type_num == 14">
				</el-table-column>
			</el-table>
		</div>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
				background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>

		<!-- 折线图1-->
		<div class="fatherBox" style="margin-top: 10px;" v-if="type_num == 1">
			<div class="ClassInfo_box">
				<div class="chartTitle">

					<ul>
						<li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index,item)"
							:class="currentIndex == index ? 'active' : ''" style="margin-top: 16px;">
							近{{ item }}天
						</li>
						<li>
							<el-date-picker v-model="searchData" type="daterange" size="small"
								:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" align="right" value-format="timestamp">
							</el-date-picker>

							<el-button @click="search()" type="primary" style="padding: 9px 20px; margin-left: 10px;">查询
							</el-button>

						</li>
					</ul>
				</div>
				<div id="main" style="width: 1000px;height:500px; background: white"></div>
			</div>
		</div>

	</div>
</template>

<script>
import {
  stuEyeSinglePic,
  stuEyeSingleSearch
} from '@/api/agentApi.js'
export default {
  data () {
    return {
      // 标题数据
      title: [
        '裸眼视力',
        '球镜（S）',
        '柱镜（C）',
        '散光轴位（A）',
        '水平子午线曲率（MM1）',
        '垂直子午线曲率（MM2）',
        '水平子午线角度（A1）',
        '瞳 距PD',
        '镜 眼 距VD',
        '正相对调节',
        '负相对调节',
        '调节灵敏度',
        '眼压',
        '雾视眼光',
        'BCC',
        'AMP调节幅度'
      ],
      dateList: ['3', '7', '30'], // 选项
      currentIndex: 0, // 天数默认
      type_num: '', // 类型数
      student_id: '', // 学生id
      currpage: 1, // 初始页
      pagesize: 10, // 每页显示条目个数
      CountNum: 0, // 总条数
      tableData: [], // 数据
      dataType: '', // 搜索类型
      searchData: [], // 搜索时间
      Series: [], // 折线图Y轴数据
      Dates: [], // 折线图X轴数据
      city: '', // 城市id
      stuname: '',
      // 时间选手
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({
          maxDate,
          minDate
        }) => {
          this.choiceDate0 = minDate.getTime()
          if (maxDate) {
            this.choiceDate0 = ''
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime()
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1)
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1)
          const min = minTime
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
          const max = newDate < maxTime ? newDate : maxTime
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate
        }
      }
    }
  },
  mounted () {
    this.student_id = this.$route.params.student_id // 学生id赋值😜
    this.type_num = this.$route.params.type // 类型赋值😜
    this.city = this.$route.params.city // 城市id赋值😜
    this.stuname = this.$route.params.stuname

    this.stuEyeSingleSearchList(1)
    this.doThis(3)
    this.healthHistory(1)
  },
  methods: {
    Atpitch (index, item) { // 折线图时间选择索引
      this.currentIndex = index

      switch (index) {
        case 0:
          this.healthHistory(1)
          this.doThis(3)
          break
        case 1:
          this.healthHistory(2)
          this.doThis(7)
          break
        case 2:
          this.healthHistory(3)
          this.doThis(30)
          break
        default:
          this.healthHistory(1)
          this.doThis(3)
      }
    },
    // 选择时间
    doThis (num) {
      const new_date = new Date() // 获取系统当前时间
      const start_date = (new_date.getTime()).toFixed(0)
      const end_date = (new Date(new_date.getTime() - num * 24 * 60 * 60 * 1000).getTime()).toFixed(0)
    },
    search () {
      this.healthHistory(4)
      this.currentIndex = -1
    },
    stuEyeSingleSearchList (currpage) { // 带有查询时分页 当有条件查询 分页时走这个方法
      this.loading = true

      const data = {
        page: currpage, // 第一次查询时候默认1
        limit: this.pagesize, // 总数
        type: this.type_num, // 类型编号
        studentid: this.student_id // 学生编号
      }
      stuEyeSingleSearch(data).then(res => {
        // console.log('11111111111______',res.data);
        this.tableData = res.data.list // 数据
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.loading = false
        // 在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
        this.currpage = currpage
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange (val) {
      this.currpage = val
      this.stuEyeSingleSearchList(val)
    },
    // 折线图日期数据
    healthHistory (type) {
      if (type == 4) {
        if (this.searchData == null) {
          return false
        } else if (this.searchData.length == 0) {
          return false
        }

        var data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          type: 1,
          studentid: this.student_id,
          city: this.city,
          startDate: Math.floor(this.searchData[0] / 1000), // 开始时间 时间戳
          endDate: Math.floor(this.searchData[1] / 1000) // 结束时间  时间戳
        }
      } else {
        var data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          type: 1,
          studentid: this.student_id,
          city: this.city
        }
      }

      stuEyeSinglePic(data).then(res => {
        this.loading = false

        this.Dates = res.data.date
        this.Series = [{
          name: '左眼',
          type: 'line',
          // data:this.year[this.nowYear]
          data: res.data.Leye
        },
        {
          name: '右眼',
          type: 'line',
          // data:this.year[this.nowYear]
          data: res.data.Reye
        }
        ]
        this.drawChart() // 执行Echarts
      }).catch(err => {
        console.log(err)
      })
    },
    drawChart () {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
          //   formatter(params){
          //     console.log(params);
          //   }
        },
        legend: {
          data: ['左眼', '右眼']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: that.Series
      }
      myChart.setOption(options)
    }
  }
}
</script>

<style lang="scss" scoped>
	.fatherBox {
		width: 98%;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			margin-left: 25px;
			border-bottom: 2px solid #e9e9e9;

			.facility {
				display: flex;

				p {
					color: burlywood;

				}

				p:nth-child(3) {
					color: black;
				}
			}

			.headerinfos {
				display: flex;

				p {
					color: burlywood;

				}

				p:nth-child(5) {
					color: black;
				}

				p:nth-child(3) {
					cursor: pointer;
				}
			}

			p {
				font-size: 16px;
				font-family: 萍方-简;
				margin-left: 10px;
			}

			.stulist {
				color: #8C8C8C;
				cursor: pointer;
				// margin: 0 3px 0 32px;
			}
		}

		.headinfo {
			width: 745px;
			display: flex;
			margin-left: 100px;

			div {
				width: 373px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	.ClassInfo_box {
		width: 96%;
		margin: 0 auto;
		background: white;
		margin-top: 25px;

		.chartTitle {
			height: 51px;
			display: flex;
			align-items: center;

			// justify-content: space-between;
			//   border-bottom:1px solid #cccccc ;
			.head_dates {
				display: inherit;
				margin-left: 102px;
				font-weight: bold;
			}

			.facility {
				font-size: 16px;
				line-height: 24px;
				color: #1890FF;
				margin-left: 32px;
				font-family: PingFangSC-Medium;
			}

			ul {
				padding: 0;
				margin: 0;
				font-size: 14px;
				margin-left: 54px;

				li {
					float: left;
					list-style: none;
					padding-left: 33px;
				}

				li:hover {
					cursor: pointer
				}
			}
		}
	}

	.active {
		//检测人滑动点击css
		color: #1890FF;
		font-size: 14px;
	}
</style>
